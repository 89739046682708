@import "../../../styles/sass/NewThemeVariables.scss";

.runName-config-dialog-container {
  min-width: 500px;
  font-size: $primaryFontSizeXS;
  font-family: $fontFamily;
  .runName-input-field-container {
    margin: 10px 20px;
    padding: 0px;
    font-size: $primaryFontSizeS;
    width: 94%;
    height: 74px;
    .run-name-warning {
      color: red;
    }
    label {
      color: $primaryColor;
      font-weight: 700;
      margin-top: 5px;
    }
    .input-label {
      width: 99%;
      height: 26px;
      border: 1px solid $inputEleBorderColor;
      color: $primaryColor;
      border-radius: $primaryBR;
    }
    .name-config {
      display: flex;
      background: white;
      padding: 5px;
      // width: 623px;
      color: $primaryColor;
      border: 1px solid $inputEleBorderColor;
      border-radius: 3px;
      height: 20px;
      input[type="text"] {
        width: 99%;
        color: $primaryColor;
        border-radius: $primaryBR;
        border: none;
      }
      .config-name {
        display: flex;
        overflow: visible;
        color: #989494;
      }
    }
  }
  .popup-btn {
    display: flex;
    justify-content: flex-end;
    border-top: none !important;
    padding: 10px 0px;
    margin: 0 5px 0px 0;
  }
}
