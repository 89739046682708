@import "../../../styles/sass/NewThemeVariables.scss";

footer {
  display: flex;
  height: $footerHeight;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .footer-text {
    margin: 0px;
    font-size: 11px;
  }
}

footer.light {
  background-color: $headerIconColor;
  position: fixed;
  bottom: 0px;
  width: 100%;

  .footer-text {
    color: $footerFontColor;
  }
}
