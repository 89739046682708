@import "../../../../../styles/sass/NewThemeVariables.scss";

.pilot-dates-container {
  display: flex;
  padding-bottom: 10px;
  .input-item-label-less {
    display: flex;
    flex-direction: row;
    label {
      margin-right: 5px;
      margin-top: 5px;
      max-width: 130px;
      font-size: $primaryFontSizeS;
    }
    input[type="date"] {
      border-radius: 3px;
      border: 1px solid $inputEleBorderColor;
      height: $inputEleHeight;
      width: 135px;
      color: $primaryColor;
      box-sizing: border-box;
      padding-left: $inputLeftSpacing;
      text-transform: uppercase;
      font-family: "Hind Siliguri";
      font-size: $primaryFontSizeXS;
      margin-left: -1px;
    }
  }
}
