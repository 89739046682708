@import "../../../styles/sass/NewThemeVariables.scss";

.power-bi-container {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // height: $noDataAvailableMinHeight;
  background: $appBGColorLight;
  .power-bi-container-1 {
    height: $noDataAvailableMinHeight;
  }
  .power-bi-container-expanded {
    width: calc(100% - 65px);
    height: calc(100vh - 100px);
    top: 80px !important;
    right: 0;
    position: absolute;
    z-index: 1;
  }
  .power-bi-container-expanded-for-sideNav-expanded {
    width: calc(100% - 235px);
    height: calc(100vh - 100px);
    top: 80px !important;
    right: 0;
    position: absolute;
    z-index: 3;
  }
  .zoom-icon-full-div-for-sidenav-expanded {
    top: 50px;
    position: absolute;
    z-index: 2;
    font-size: 17px;
    right: 0;
    width: calc(100% - 235px);
    background-color: $sidenavBGColorLight;
    border-radius: 5%;
    height: 30px;
    color: $primaryColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 10px 10px rgba(221, 221, 221, 0.5);
    .zoom-icon-full-screen {
      color: $primaryColor;
      padding-right: 1%;
      font-size: 20px;
      font-weight: bolder;
    }
  }
  .zoom-icon-full-div {
    top: 50px;
    position: absolute;
    z-index: 2;
    font-size: 17px;
    // left: 0;
    right: 0;
    width: calc(100% - 65px);
    background-color: $sidenavBGColorLight;
    border-radius: 2%;
    height: 30px;
    color: $primaryColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 10px 10px rgba(221, 221, 221, 0.5);
    .zoom-icon-full-screen {
      color: $primaryColor;
      padding-right: 1%;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .zoom-icon-full-text {
    padding-left: 1%;
    font-size: 14px;
    font-style: normal;
    font-family: $fontFamily;
    font-weight: bold;
  }
  .zoom-icon-div {
    z-index: 2;
    font-size: 17px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .zoom-icon {
      // top: 41%;
      // right: 4%;
      // position: absolute;
      // z-index: 2;
      font-size: 17px;
      margin: -50px 10px 5px 0px;
    }
  }
  .comment-container {
    width: 350px;
    height: 100%;
    z-index: 12;
    background: #ffffff;
    box-shadow: -1px 0px 0px 0px #ecebeb;
    h3 {
      color: #46596a;
      font-size: 16px;
      padding: 0 13px;
    }
  }
  .radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .radio-label {
      color: #46596a !important;
      font-weight: 400;
      width: fit-content !important;
      font-size: $primaryFontSizeS !important;
      text-transform: capitalize !important;
    }
    .testTransform {
      text-transform: inherit !important;
    }
  }
}
