@import "../../../styles/sass/NewThemeVariables.scss";

.notification-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .notification-header {
    text-transform: uppercase;
    padding: 0px 15px;
    color: $secondaryColor;
    font-weight: 900;
    font-size: 14px;
  }
  .notification-button {
    margin-left: auto;
    margin-right: 15px;
    font-size: 11px;
    height: 20px;
    background-color: $primaryColor;

    &:focus {
      outline: none;
    }
  }
}

.notification-message-container {
  //padding: 0px 15px;
  color: $primaryColor;
  font-size: $primaryFontSizeXS;
  max-height: 300px;
  overflow-y: auto;
  width: 350px;

  .no-notification-message {
    text-align: center;
    margin-bottom: 20px;
  }

  .notification-container {
    &.unread {
      background-color: $sidenavActiveBGColor;
    }
    .notification-message-row {
      padding: 5px 15px 0px;
      display: flex;
      align-items: center;
      .run-icon {
        margin-right: 7px;
        width: 20px;
        height: 20px;
      }
      .notification-message {
        margin: 0px;
      }

      .run-icon {
        margin-right: 7px;
        min-width: 30px;
        height: 22px;

        &.run-triggered {
          background-image: url(../../../assets/images/run_normal.svg);
          background-repeat: no-repeat;
        }

        &.run-success {
          background-image: url(../../../assets/images/run_sucess.svg);
          background-repeat: no-repeat;
        }

        &.run-failure {
          background-image: url(../../../assets/images/run_fail.svg);
          background-repeat: no-repeat;
        }
        &.run-cancelled {
          background-image: url(../../../assets/images/run_cancel.svg);
          background-repeat: no-repeat;
        }
      }
    }

    .notification-separator {
      color: #46596a;
      background-color: #46596a;
      height: 0.1;
      opacity: 0.5;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .notification-sub-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 15px;

      .nav-run-item {
        margin-left: auto;
        //margin-right: 15px;
        .notification-run-message {
          margin-top: 5px;
          font-size: 11px;
          color: $secondaryColor;
          text-decoration: underline;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }

      .notification-time-message {
        margin-top: 5px;
        opacity: 0.5;
        font-size: 11px;
        text-transform: capitalize;
        margin-bottom: 5px;
      }
    }

    &:last-child {
      margin-bottom: 10px;
      .notification-separator {
        border: none;
      }
    }
  }
}
