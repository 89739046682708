@import "../../../../../styles/sass/NewThemeVariables.scss";

.test-control-global-text,
.test-control-global-text-no-padding {
  display: flex;
  // flex-direction: row;
  // align-items: center;

  label {
    margin-right: 5px;
    font-weight: 600;
    margin-bottom: 0px;
    width: fit-content;
    font-size: $primaryFontSizeS;
    font-family: "Hind Siliguri" !important;
    padding: 5px;
  }
}
.test-control-global-text-no-padding {
  label {
    padding-left: 0;
  }
}
