.budget-runs-conatiner {
    .configs-container {
      display: flex;
      // justify-content: space-between;
      align-items: center;
  
      .rbt_run_id {
        width: 70%;
        label {
          width: 25% !important;
        }
        select {
          width: 75% !important;
        }
      }
  
      .dayCat {
        width: 30%;
      }
    }
  }
  