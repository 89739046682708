@import "../../../../../styles/sass/NewThemeVariables.scss";

.disabled-input {
  height: 30px !important;
}

.sku-container {
  margin-top: 10px;
  margin-right: 15px;
  padding: 5px 8px 0px 4px;
}

.global_week_date_selection {
  height: 28px;
  .date-range-text {
    margin-right: 15px;
  }
  .radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .radio-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px !important;

      .radio-label {
        color: #46596a !important;
        font-weight: 400;
        width: fit-content !important;
        font-size: $primaryFontSizeS !important;
        text-transform: capitalize !important;
      }
      .testTransform {
        text-transform: inherit !important;
      }
    }
  }
  .input-item {
    select,
    input[type="number"],
    input[type="date"],
    input[type="text"] {
      border-radius: 3px;
      border: 1px solid $inputEleBorderColor;
      height: 28px;
      width: 158px !important;
      color: $primaryColor;
      box-sizing: border-box;
      padding-left: $inputLeftSpacing;
      font-family: "Hind Siliguri";
      font-size: $primaryFontSizeXS;
    }
    #date-range-input-start {
      width: 187px !important;
      margin-left: 5px;
      display: inline-block;
    }
    #date-range-input-end {
      width: 187px !important;
      display: inline-block;
    }
  }
}

.single-dropdown {
  display: flex;
  flex-direction: column;
}

.popover-content {
  padding: 10px;
}

.date-range {
  display: flex;
  width: 40%;
  // flex-direction: row;
  .input-item {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    //height: 60px;
    font-size: $primaryFontSizeS;
  }
}

.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .radio-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;

    .radio-label {
      color: #46596a !important;
      font-weight: 400;
      width: fit-content !important;
      font-size: $primaryFontSizeS !important;
      text-transform: capitalize !important;
    }
  }
}
.no-kpi-container {
  margin: 10px;
  .no-kpi-image {
    display: flex;
    justify-content: center;
  }
}
.input-item {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  //height: 60px;
  font-size: $primaryFontSizeS;

  &.audience_name {
    flex-direction: row;
    margin-top: 10px;
    label {
      margin-right: 5px;
      font-weight: 600;
      margin-bottom: 0px;
      width: 19%;
      font-size: $primaryFontSizeS;
      font-family: "Hind Siliguri" !important;
    }
    input[type="text"] {
      border-radius: 3px;
      border: 1px solid $inputEleBorderColor;
      height: $inputEleHeight;
      width: 81% !important;
      color: $primaryColor;
      box-sizing: border-box;
      padding-left: $inputLeftSpacing;
      font-family: "Hind Siliguri";
      font-size: $primaryFontSizeXS;
    }
  }

  &.weeks_pilot,
  &.incremental_spend,
  &.min_ci,
  &.expected_roas,
  &.sales_coverage {
    height: fit-content;
    font-size: $primaryFontSizeS;
  }

  &.no_of_similar_regions,
  &.number_of_cells {
    input[type="number"].similar_regions::-webkit-outer-spin-button,
    input[type="number"].similar_regions::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.row {
    flex-direction: row;
    align-items: center;
    height: 30px;

    label {
      margin-right: 5px;
      font-weight: 600;
      margin-bottom: 0px;
      width: 70px;
      font-size: $primaryFontSizeXS;
      font-family: "Hind Siliguri" !important;
    }

    // SPECIFIC CSS

    &.target-variable {
      padding: 8px;
      label {
        width: auto;
        font-size: $primaryFontSizeS;
      }
    }

    &.regionFilterType,
    &.retail_margin_price {
      label {
        width: auto;
      }
    }

    &.channel_granularity {
      label {
        width: 70px;
        font-size: $primaryFontSizeS;
      }
    }

    &.run_type {
      border-left: 1px solid #e2e2e2;
      padding-left: 10px;
      label {
        width: fit-content;
      }
      .radio-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .radio-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0px !important;

          .radio-label {
            color: #46596a !important;
            font-weight: 400;
            width: fit-content !important;
            font-size: $primaryFontSizeS !important;
            text-transform: capitalize !important;
          }
        }
      }
    }

    &.config_id {
      label {
        width: 30%;
      }
      select {
        width: 70%;
      }
    }

    &.dayCat {
      label {
        width: 30%;
        font-size: $primaryFontSizeS;
      }
      .single-select-dropdown {
        width: 70% !important;
      }
    }

    &.side-view-runs-filter {
      margin-right: 0px !important;
      width: 100%;
      .single-select-dropdown {
        width: 150px !important;
        width: 70%;
      }
    }

    &.channel_granularity {
      .ChannelNameSection {
        label {
          position: relative;
          //top: -1.5px;
          top: -12.5px;
          right: -21px;
          font-weight: 300;
          font-size: $primaryFontSizeXS;
        }
      }
    }

    &.volume_field {
      margin-bottom: 10px;
    }

    &.periodicity {
      padding: 5px 8px 0px 4px;
      margin-right: 15px;

      .single-select-dropdown {
        width: 100% !important;
      }
    }

    &.minimum_datapoint_column {
      margin-top: 10px;
      label {
        width: 116px;
        font-size: $primaryFontSizeS;
        margin-right: 0 !important;
      }
      .single-select-dropdown {
        width: 190px;
      }
    }

    &.category,
    &.periodicity,
    &.statistical_measure,
    &.preferred_sales_dataset,
    &.volume_field {
      label {
        width: auto;
        font-size: $primaryFontSizeS;
      }
      .single-select-dropdown {
        width: 190px;
      }
    }

    &.volume {
      label {
        width: 80px;
      }
    }

    &.pilot_run_id,
    &.run_id,
    &.rbt_run_id,
    &.level_name,
    &.nested_fields {
      label {
        width: 19%;
        font-size: $primaryFontSizeS;
      }
      .single-select-dropdown {
        width: 81%;
      }
      textarea {
        margin-left: 130px;
        width: 81%;
      }
    }
  }

  label {
    margin-bottom: 5px;
    color: $primaryColor;
    font-weight: 600;
    text-transform: capitalize;
  }

  .input_info {
    color: $primaryColor;
    font-size: 10px;
    opacity: 0.7;
  }

  input[type="date"] {
    border-radius: 3px;
    border: 1px solid $inputEleBorderColor;
    height: $inputEleHeight;
    width: 135px;
    color: $primaryColor;
    box-sizing: border-box;
    padding-left: $inputLeftSpacing;
    text-transform: uppercase;
    font-family: "Hind Siliguri";
    font-size: $primaryFontSizeXS;
    //margin-left: -8px;
  }

  textarea {
    border-radius: 3px;
    border: 1px solid $inputEleBorderColor;
    height: 250%;
    width: 81%;
    margin-top: 40px;
    color: $primaryColor;
    box-sizing: border-box;
    padding-left: $inputLeftSpacing;
    font-family: "Hind Siliguri";
    font-size: $primaryFontSizeXS;
  }

  select,
  input[type="number"],
  input[type="date"],
  input[type="text"] {
    border-radius: 3px;
    border: 1px solid $inputEleBorderColor;
    height: $inputEleHeight;
    width: $inputElementWidth;
    color: $primaryColor;
    box-sizing: border-box;
    padding-left: $inputLeftSpacing;
    font-family: "Hind Siliguri";
    font-size: $primaryFontSizeXS;
  }
}
.country-brand-dropdown {
  input[type="text"] {
    width: 180px !important;
  }
}
.input-item-label-less {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  font-size: $primaryFontSizeS;

  label {
    margin-bottom: 5px;
    color: $primaryColor;
    font-weight: 600;
    text-transform: capitalize;
  }

  select,
  input[type="number"],
  input[type="text"] {
    border-radius: 3px;
    border: 1px solid $inputEleBorderColor;
    height: $inputEleHeight;
    width: $inputElementWidth;
    color: $primaryColor;
    box-sizing: border-box;
  }
}

.country-brand-dropdown {
  input[type="text"] {
    width: 180px !important;
  }
}
// .ChannelNameSection {
//   width: 90px;
// }

// .ChannelNameSection input[type="radio"] {
//   visibility: hidden;
//   width: 20px;
//   height: 20px;
//   margin: 0 5px 0 5px;
//   padding: 0;
// }
// .ChannelNameSection input[type="radio"]:before {
//   position: relative;
//   //margin: 4px -25px -4px 0;
//   margin: 19px -23px -3px -4px;
//   display: inline-block;
//   visibility: visible;
//   width: 14px;
//   height: 14px;
//   border-radius: 10px;
//   //border: 2px inset rgba(150,150,150,0.75);
//   border: 1px solid $inputEleBorderColor;
//   background: #fff;
//   //background: radial-gradient(ellipse at top left, rgb(255,255,255) 0%, rgb(250,250,250) 5%, rgb(230,230,230) 95%, rgb(225,225,225) 100%);
//   content: "";
// }
// .ChannelNameSection input[type="radio"]:checked:after {
//   position: relative;
//   top: 1px;
//   left: 9px;
//   display: inline-block;
//   visibility: visible;
//   border-radius: 6px;
//   width: 12px;
//   height: 12px;
//   background: radial-gradient(
//     ellipse at top left,
//     #68c7ee 0%,
//     #68c7ee 5%,
//     #25b1e6 95%,
//     #25b1e6 100%
//   );
//   //background: radial-gradient(ellipse at top left, rgb(245,255,200) 0%, rgb(225,250,100) 5%, rgb(75,175,0) 95%, rgb(25,100,0) 100%);
//   content: "";
// }
// .ChannelNameSection input[type="radio"].true:checked:after {
//   background: radial-gradient(
//     ellipse at top left,
//     rgb(245, 255, 200) 0%,
//     rgb(225, 250, 100) 5%,
//     rgb(75, 175, 0) 95%,
//     rgb(25, 100, 0) 100%
//   );
// }
// .ChannelNameSection input[type="radio"].false:checked:after {
//   background: radial-gradient(
//     ellipse at top left,
//     rgb(255, 225, 200) 0%,
//     rgb(250, 200, 150) 5%,
//     rgb(200, 25, 0) 95%,
//     rgb(100, 25, 0) 100%
//   );
// }
// .ChannelNameSection label {
//   display: inline-block;
//   height: 25px;
//   line-height: 25px;
//   margin: 0;
//   padding: 0;
// }
// .ChannelNameSection input[type="radio"]:disabled:checked:after {
//   position: relative;
//   top: 1px;
//   left: 9px;
//   display: inline-block;
//   visibility: visible;
//   border-radius: 6px;
//   width: 12px;
//   height: 12px;
//   cursor: not-allowed;
//   background: radial-gradient(
//     ellipse at top left,
//     #e2e2e2 0%,
//     #e2e2e2 5%,
//     #aba8a8 95%,
//     #e2e2e2 100%
//   ) !important;
//   //background: radial-gradient(ellipse at top left, rgb(245,255,200) 0%, rgb(225,250,100) 5%, rgb(75,175,0) 95%, rgb(25,100,0) 100%);
//   content: "";
// }
// .ChannelNameSection input[type="radio"]:disabled:before {
//   cursor: not-allowed !important;
// }
