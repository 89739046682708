.sales-radio-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  // align-items: center;
  // justify-content: flex-start;
  //margin-left: 8px;

  .radio-icon {
    display: flex;
    align-items: center;

    .radio-label {
      width: 60px !important;
      font-weight: 400;
    }
  }
}
