@import "../../../../../styles/sass/NewThemeVariables.scss";

.multiset-popup-data-row {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  //align-items: center;

  .input-item {
    flex-direction: row;
    padding: 4px 0px;
    label {
      max-width: 250px;
      width: 175px !important;
      min-width: fit-content;
      text-overflow: ellipsis !important;
      text-transform: capitalize !important;
      white-space: nowrap !important;
      overflow: hidden !important;
      font-size: $primaryFontSizeXS;
      text-align: end;
      padding-right: 10px;
    }
    &.saturation_flag {
      label {
        // width: fit-content !important;
        // min-width: fit-content !important;
        max-width: 250px;
        font-size: $primaryFontSizeS;
        //width: 170px !important;
        margin-right: 0px;
        min-width: fit-content;
      }
    }

    .input-item-box {
      display: flex;
      flex-direction: column;
      .input-info {
        color: $primaryColor;
        font-size: 10px;
        opacity: 0.7;
      }
      input {
        width: 150px !important;
      }
    }
  }
}
