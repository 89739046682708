.region-radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;

  .radio-icon {
    display: flex;
    align-items: center;
    padding-right: 12px;

    .radio-label {
      width: fit-content !important;
      margin-bottom: 0px;
      font-weight: 400 !important;
    }
  }
}
