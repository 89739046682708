@import "../../../../../styles/sass/NewThemeVariables.scss";
.test-control-container {
  display: flex;
  //flex-direction: column !important;
  width: 80%;

  .test-control {
    display: flex;
    align-items: flex-start !important;
    //margin-top: 22px !important;
    margin-bottom: -1px !important;
    // border: 1px solid $headerIconColor;
    // border-radius: 4px;
    padding: 10px;

    & .title {
      margin-left: 0px !important;
    }

    .show-more {
      pointer-events: all !important;
    }
  }
}
