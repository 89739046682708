@import "../../../styles/sass/NewThemeVariables.scss";

.country-brand-container {
  display: flex;
  align-items: center;
  .country-section {
    margin-right: 30px;
  }
  .input-item {
    width: 30%;
  }
  .go-button {
    margin-top: 26px;
    margin-left: 30px;
  }
  .no-data {
    font-size: 1rem;
    color: $primaryColor;
    text-align: center;
  }
}
