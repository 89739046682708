@import "../../../../../styles/sass/NewThemeVariables.scss";

.test-control-form {
  //width: 850px;
  height: auto;
  margin-top: 15px;

  .popup-btn {
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin: 7px 15px;
    border-top: 1px solid $inputEleBorderColor;
    padding: 10px 0px;
  }
}

.multi-test-control-container-row {
  margin-left: 0px !important;

  .text-title {
    //width: 70px;
    min-width: 44px;
    //margin: 0px 20px;
    color: $secondaryColor !important;
    font-size: $primaryFontSizeS;
    font-weight: 600;
  }

  .test-control {
    //display: flex;
    //align-items: center;
    margin: 15px 0px;
    // .multi-select-container {
    //   //width: 90%;
    // }
  }

  .multiple-test-control-container {
    display: flex;
    margin-left: 0px;
    background-color: #fff;
    border: 1px solid $inputEleBorderColor;
    max-width: 950px;
    min-width: 400px;
    width: 100%;
    border-radius: 3px;
    justify-content: space-between;
    padding-right: 20px;

    .test-control-button {
      position: relative;
      width: 15%;
      //top: 15px;
    }
  }
}

.popup-btn-test-control {
  // border: 0px;
  background: #ffffff;
  border: 2px solid $primaryColor;
  box-sizing: border-box;
  border-radius: 5px;
}

.multi-test-control-container {
  display: flex;
  flex-direction: row;
  //margin-top: 12px;
  padding: 7px 15px;
}

.test-control-regions {
  //flex-direction: column;
  background-color: $appBGColor !important;
  border: 0px solid $sidenavBorderColor !important;
  //border-radius: 4px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  .test-autocomplete-input {
    width: 90%;
  }
  .control-autocomplete-input {
    width: 100%;
  }
  .test-control-regions-second-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.popover-content {
  padding: 10px;
}

.error {
  margin-left: 20px;
}
