@import "../../../../../styles/sass/NewThemeVariables.scss";

.campaign {
  // margin-bottom: 10px;
  // flex-direction: column;
  // align-items: left !important;
  // justify-content: space-between;
  //margin-bottom: 10px;
  flex-direction: column;
  background-color: $appBGColor;
  //border-radius: 4px;
  padding: 10px 0px 0px;
  //border: 1px solid $inputEleBorderColor;

  select {
    width: 88% !important;
    height: $inputEleHeight;
    padding-left: $inputLeftSpacing;
    margin-right: 1px;
    font-size: $primaryFontSizeXS;
  }

  .campaign-dates-container {
    // display: flex;

    .campaign-dates-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // width: 590px;
      .input-item-label-less {
        label {
          margin-top: 5px;
          // max-width: 130px;
          font-size: 14px;
          font-weight: 600;
        }
        input[type="date"] {
          border-radius: 3px;
          border: 1px solid $inputEleBorderColor;
          height: $inputEleHeight;
          width: 225px;
          color: $primaryColor;
          box-sizing: border-box;
          padding-left: $inputLeftSpacing;
          text-transform: uppercase;
          font-family: "Hind Siliguri";
          font-size: $primaryFontSizeXS;
          margin-left: -1px;
        }
      }
    }
  }

  .delete-icon {
    font-size: 20px;
    opacity: 0.6;
    margin-right: 10px;
    margin-top: 30px;
    //margin-left: 7px;
  }

  .edit-icon {
    font-size: 20px;
    opacity: 0.6;
    //margin-right: 5px;
    margin-top: 30px;
    //margin-left: 7px;
  }

  .done-icon {
    font-size: 20px;
    color: $fifthColor;
    //margin-right: 5px;
    margin-top: 30px;
    //margin-left: 7px;
  }

  .add-icon {
    color: $fifthColor;
    font-size: 20px;
    margin-top: 30px;
    margin-left: 7px;
  }
}
