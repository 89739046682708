@import "../../../styles/sass/NewThemeVariables.scss";

.breadcrumb {
  margin-bottom: 15px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  .copy-run-id {
    margin: 5px 0 0 10px;
  }
  .breadcrumb-item {
    font-size: $primaryFontSizeS;
    text-decoration: none !important;
    text-transform: capitalize;
    color: inherit;

    &.active {
      font-weight: 600;
      text-decoration: none !important;
      color: inherit;
    }
  }
}

.breadcrumb.light {
  color: $sidenavActiveFontColor;
  .breadcrumb-item {
    color: inherit;

    &.active {
      color: $primaryColor;
      text-transform: none;
      color: inherit;
    }
  }
}
