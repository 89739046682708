@import "../../../styles/sass/NewThemeVariables.scss";

.scheduled-config-icon {
  color: $secondaryColor;
  font-size: $primaryFontSizeXXL !important;
  margin-right: 7px;
}

.rows-icons-style {
  margin-right: 7px;
  width: 20px !important;
  height: 20px !important;
}

.table-row {
  color: $primaryColor;
}

.favourite-icon {
  padding-right: 5px;
  font-size: $iconsizeL;
  cursor: pointer;
}

.config-name {
  white-space: nowrap;
  max-width: 460px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete_icon {
  font-size: $iconS;
  color: $primaryColor;
}

.run-icon {
  margin-right: 7px;
  width: 20px;
  height: 20px;

  &.Running {
    // width: 30px;
    // height: 30px;
    background-image: url(../../../assets/images/running.svg);
    background-repeat: no-repeat;
  }

  &.Queued {
    background-image: url(../../../assets/images/run_normal.svg);
    background-repeat: no-repeat;
  }

  &.Success {
    background-image: url(../../../assets/images/run_sucess.svg);
    background-repeat: no-repeat;
  }

  &.Failed {
    background-image: url(../../../assets/images/run_fail.svg);
    background-repeat: no-repeat;
  }
  &.Cancelled {
    background-image: url(../../../assets/images/run_cancel.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.edit-tags {
  .tags-popup {
    width: 300px;
    min-height: 100px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
  }
  h2 {
    color: #465a69;
    font-weight: bold;
    font-size: 13px;
  }
  .no-tags {
    text-align: center;
  }
}

//custom chips
.chip-container {
  width: 250px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.chip-item {
  width: 80px !important;
  color: white !important;
  width: fit-content;
  border: 1px solid #dedede;
  height: 30px;
  font-size: 14px;
  background: #4eafb3;
  font-family: Hind Siliguri;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
  }
}
