@import "../../../../../styles/sass/NewThemeVariables.scss";
.datasets-selections-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.checkbox-selection-row {
  display: flex;
  align-items: center;
  .active {
    color: $fifthColor;
  }
  i {
    font-size: 15px;
    margin-right: 10px;
  }
  .checkbox-label {
    font-size: 13px;
    color: #46596a;
  }
}

.primary-button {
  background-color: white;
  border: 1px solid #4eafb3;
  color: #4eafb3;
  margin-right: 10px;
  height: 25px;
}
.secondary-button {
  background-color: #4eafb3;
  color: white;
  height: 25px;
}

.merge-dataset-name {
  color: #46596a !important;
  border: 1px solid #dedede;
  height: 30px;
  font-size: 12px;
  max-width: 200px;
  min-width: 200px;
  font-family: Hind Siliguri;
  border-radius: 3px;
  background-color: white;
  padding: 0px 5px;
  box-sizing: border-box;
}

.merge-dataset-input-label {
  margin-bottom: 5px;
  color: #46596a;
  font-weight: 600;
  text-transform: capitalize;
}

.merge-dataset-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.merge-dataset-section {
  height: 95%;
}

.button-section {
  display: flex;
  justify-content: flex-end;
}

.error-message {
  color: red;
}

.merge-dataset-source-tag {
  margin-left: 4px;
  background-color: #f5c405;
  color: #41596c;
  font-size: 8px;
  padding: 1px 4px;
  border-radius: 10px;
  font-weight: 700;
}
