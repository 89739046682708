@import "../../../../../styles/sass/NewThemeVariables.scss";

.action-name-info-container {
  display: flex;
  align-items: center;
  .info-icon {
    margin-left: 5px;
    color: $fifthColor;
    font-size: 15px;
    // &:hover {
    //   color: $secondaryColor;
    // }
  }
}

.multiset-popup-row-container {
  p {
    color: $fifthColor;
    font-size: $primaryFontSizeXS;
  }
  .error {
    color: red;
  }
}

.regions-multiset-popup-row {
  display: flex;
  padding: 10px 0px 0px;
  justify-content: space-between;
  //margin-bottom: 10px;
  //align-items: center;

  &.test_group_parameters,
  &.action_names,
  &.combinations {
    background-color: $appBGColor;
    border: 1px solid $sidenavBorderColor;
    padding: 10px 10px;
    border-radius: 4px;
  }

  .delete-icon {
    font-size: 20px;
    opacity: 0.6;
    margin-right: 5px;
    margin-top: 30px;
    //align-items: flex-end;
    display: flex;
    padding-bottom: 3px;
  }

  .edit-icon {
    font-size: 20px;
    opacity: 0.6;
    margin-right: 5px;
    margin-top: 30px;
    //align-items: flex-end;
    display: flex;
    padding-bottom: 3px;
  }

  .done-icon {
    font-size: 20px;
    color: $fifthColor;
    margin-right: 5px;
    margin-top: 30px;
    //align-items: flex-end;
    display: flex;
    padding-bottom: 3px;
  }

  .input-item {
    // .info-icon {
    //   margin-top: 10px;
    //   margin-left: 5px;
    //   width: 10%;
    //   color: $fifthColor;
    //   font-size: $primaryFontSizeXS;
    // }
    label {
      width: fit-content !important;
      text-overflow: ellipsis !important;
      text-transform: capitalize !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
    .input_info {
      color: $primaryColor;
      font-size: 10px;
      opacity: 0.7;
    }
    input {
      width: 140px !important;
    }
  }
}

.multiset-popup-row {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  //align-items: center;

  &.test_group_parameters,
  &.action_names,
  &.combinations {
    background-color: $appBGColor;
    //border: 1px solid $sidenavBorderColor;
    padding: 10px 10px;
    border-radius: 4px;
  }

  .edit-delete-action-row {
    display: flex;
    align-items: center;
    .delete-icon {
      font-size: 20px;
      opacity: 0.6;
      margin-right: 5px;
      // margin-top: 30px;
      margin-left: 5px;
      //align-items: flex-end;
      display: flex;
      padding-bottom: 3px;
    }

    .edit-icon {
      font-size: 20px;
      opacity: 0.6;
      margin-right: 5px;
      // margin-top: 30px;
      margin-left: 5px;
      //align-items: flex-end;
      display: flex;
      padding-bottom: 3px;
    }

    .done-icon {
      font-size: 20px;
      color: $fifthColor;
      margin-right: 5px;
      // margin-top: 30px;
      margin-left: 5px;
      //align-items: flex-end;
      display: flex;
      padding-bottom: 3px;
    }
  }

  .input-item {
    // .info-icon {
    //   margin-top: 10px;
    //   margin-left: 5px;
    //   width: 10%;
    //   color: $fifthColor;
    //   font-size: $primaryFontSizeXS;
    // }
    label {
      width: fit-content !important;
      text-overflow: ellipsis !important;
      text-transform: capitalize !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }
    .input_info {
      color: $primaryColor;
      font-size: 10px;
      opacity: 0.7;
    }
    input {
      width: 140px !important;
    }
  }
}
