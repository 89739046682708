@import "../../../../../styles/sass/NewThemeVariables.scss";

.dialog-popup-container,
.optimal-popup-container {
  width: 300px;
  color: #46596a;
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    border-top: 1px solid #dedede;
    padding: 10px 0;
    .primary-button {
      color: #46596a;
      height: 23px;
      padding: 10px;
      font-size: 11px;
      min-width: 20px;
      font-family: Hind Siliguri;
      border-color: #46596a;
      margin-right: 4px;
      text-transform: capitalize;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .secondary-button {
      color: white;
      height: 23px;
      padding: 10px;
      font-size: 11px;
      min-width: 20px;
      font-family: Hind Siliguri;
      border-color: #46596a;
      margin-right: 4px;
      text-transform: capitalize;
      background: #46596a;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.optimal-popup-container {
  width: 35rem;
  font-size: 15px;
  font-weight: 600;
  .button-container {
    border: none;
  }
}
