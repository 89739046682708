@import "../../../../../styles/sass/NewThemeVariables.scss";

.nested-runs-conatiner {
  .configs-container-new {
    // display: flex;
    // // justify-content: space-between;
    // align-items: center;
    display: grid;
    /* align-items: center; */
    grid-template-columns: 0.3fr 0.7fr;
    grid-row-gap: 10px;
    // .pilot_run_id {
    //   width: 65% !important;
    //   label {
    //     width: 19% !important;
    //   }
    //   select {
    //     width: 88% !important;
    //   }
    // }

    // .dayCat {
    //   width: 35% !important;
    // }
    .algorithm,
    .dayCat {
      label {
        width: 33% !important;
        font-size: 13px;
      }
      .single-select-dropdown {
        width: 70% !important;
      }
    }
  }
}

.base-driver-container {
  margin-top: 20px;
  .add-set-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .nested-test-control-container {
    display: flex !important;
    background-color: $appBGColor;
    border-radius: $primaryBR;
    border: 1px solid $inputEleBorderColor;
    margin-bottom: 10px;
    padding: 0px 10px;
    align-items: center;
    .nested-region-input {
      width: 100%;
      padding: 10px 10px;
      .region-level-input {
        display: flex;
        margin-bottom: 10px;
        &.national {
          .base-level {
            margin-right: 5px;
            width: 35% !important;
            label {
              width: 25% !important;
            }
            select {
              width: 75% !important;
            }
          }
          .base-driver {
            margin-right: 5px;
            width: 65% !important;
            label {
              width: 10% !important;
            }
            select {
              width: 90% !important;
            }
          }
        }
        &.regional {
          .base-level {
            margin-right: 5px;
            width: 65% !important;
            label {
              // width: 18%;
              width: 15% !important;
              margin-left: 30px;
            }
            select {
              width: 80% !important;
            }
          }
          .regions {
            margin-right: 5px;
            width: 35% !important;
            label {
              width: 30% !important;
            }
            select {
              width: 70% !important;
            }
          }
        }
      }
      .test-base-driver {
        width: 100%;
        label {
          width: 10.22% !important;
        }
        select {
          width: 88.75% !important;
        }
      }
    }
  }
}
